import Link from 'next/link';
import { cn } from '@kit/ui/utils';

function LogoImage({
  className,
}: {
  className?: string;
}) {
  return (
    <img
      className={cn("hover:animate-spin-slow mt-[-5px] h-[45px] w-auto", className)}
      src="/images/logo.webp"
      alt="Content Flywheel"
    />
  );
}

export function AppLogo({
  href,
  label,
  className,
}: {
  href?: string;
  className?: string;
  label?: string;
}) {
  return (
    <Link aria-label={label ?? 'Home Page'} href={href ?? '/'}>
      <LogoImage className={className} />
    </Link>
  );
}
