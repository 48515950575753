import { useEffect } from 'react';

import { useMonitoring } from './use-monitoring';

export function useCaptureException(error: Error) {
  const service = useMonitoring();

  process?.on('uncaughtException', (err) => {
    console.error('Custom Error:', err.message);
  });

  Error.prepareStackTrace = (err, stack) => {
    return stack
      .map((callSite) => callSite.toString())
      .slice(0, 5)
      .join('\n');
  };

  useEffect(() => {
    void service.captureException(error);
  }, [error, service]);
}
